.btn-icon-setting {
    color: #fff;
    font-size: 18px;
    border-radius: 5px;
    background-color: #4c8bf2;
}

.btn-icon-setting:hover, .btn-icon-setting:focus, .btn-icon-setting:active, .btn-icon-setting:active:hover, .btn-icon-setting:disabled {
    color: #fff;
    background-color: #3869b5 !important;
    border-color: #3869b5 !important;
}

.btn-setting {
    color: #fff;
    border-radius: 7px;
    background-color: #4c8bf2;
}

.btn-setting:hover, .btn-setting:focus, .btn-setting:active, .btn-setting:active:hover, .btn-icon-setting:disabled {
    color: #fff;
    background-color: #3869b5 !important;
    border-color: #3869b5 !important;
}

.btn-setting-add {
    color: #fff;
    border-radius: 5px;
    background-color: #f39800;
}

.btn-setting-add:hover, .btn-setting-add:focus, .btn-setting-add:active, .btn-setting-add:active:hover, .btn-setting-add:disabled {
    color: #fff;
    background-color: #f9ac50 !important;
    border-color: #f9ac50 !important;
}

.btn-delete {
    color: #fff;
    border-radius: 7px;
    background-color: #595757;
}

.btn-delete:hover, .btn-delete:focus, .btn-delete:active, .btn-delete:active:hover, .btn-delete:disabled {
    color: #fff;
    background-color: #1b262f !important;
    border-color: #1b262f !important;
}

.btn-cancel {
    color: #fff;
    border-radius: 5px;
    background-color: #898989;
}

.btn-cancel:hover, .btn-cancel:focus, .btn-cancel:active, .btn-cancel:active:hover, .btn-cancel:disabled {
    color: #fff;
    background-color: #676767 !important;
    border-color: #676767 !important;
}

.spinner-color {
    color: #4c8bf2 !important;
}
