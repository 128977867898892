.sidebar .nav li>a,
.sidebar .nav li>a .st0 {
  color: #364b5d !important;
  stroke: #364b5d;
  font-weight: bold;
  font-size: 14px !important;
}

.sidebar .nav li>a.active {
  color: #2f8bee !important;
  opacity: 1;
  stroke: #2f8bee;
  font-weight: bold;
}

.sidebar .nav li>a.active>i {
  color: #2f8bee !important;
  opacity: 1;
  stroke: #2f8bee;
  font-weight: bold;
}

.label-text {
    font-weight: bold !important;
    font-size: 14px !important;
    color: #364b5d !important;
}

.fs-1-5 {
    font-size: 1.5em !important;
}

.c-blue2 {
    color: #4a8cf1;
}

.logo-title-text {
  font-size: 20px !important;
  font-weight: bold !important;
  color: #364b5d !important;
}
