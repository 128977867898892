.modal-title {
    font-size: 24px;
    font-weight: 500;
    color: #364b5d;
}

.modal-secondary-title {
    font-size: 18px;
    font-weight: normal;
    color: #364b5d;
}

.modal-form-label {
    font-size: 14px;
    color: #364b5d;
}

.modal-footer-center {
    display: flex;
    justify-content: center;
}

.modal-text {
    color: #4a8cf1;
}
