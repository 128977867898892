.card-title {
    font-size: 24px;
    font-weight: 500;
    color: #364b5d;
}

.card-money-signal {
    font-size: 20px !important;
    font-weight: bold !important;
    color: #364b5d !important;
}
