.fade-enter .fade-card {
    opacity: 0;
    transform: translateX(-100%);
}

.fade-enter-active .fade-card {
    opacity: 1;
    transform: translateX(0%);
}

.fade-exit .fade-card {
    opacity: 1;
    transform: translateX(0%);
}

.fade-exit-active .fade-card {
    opacity: 0;
    transform: translateX(100%);
}

.fade-enter-active .fade-card,
.fade-exit-active .fade-card {
    transition: opacity 500ms, transform 500ms;
}

.blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
