.td-customize {
    background-color: #ffffff;
    border-top: 1px solid #bfbfbf !important;
    border-bottom: 1px solid #bfbfbf !important;
    font-weight: normal;
    color: #656e77;
    background-clip: padding-box;
}

.th-customize {
    background-color: #f1f4f7;
    border-top: 1px solid #bfbfbf !important;
    border-bottom: 1px solid #bfbfbf !important;
    font-weight: bold;
    color: #364b5d;
    background-clip: padding-box;
}
