.edge {
    stroke-dasharray: 5;
    animation: dashdraw .5s linear infinite;
    stroke-width: 1;
}

@keyframes dashdraw {
    0% { stroke-dashoffset: 10; }
}

.node {
    stroke: '#1a192b';
}

.arrow {
    fill: '#b1b1b7';
}
